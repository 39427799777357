<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "เพิ่ม" : "แก้ไข" }} ข้อมูล</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
    
      <div class="p-6">

        <vs-input label="รหัสหน่วยงาน" v-model="org_id" class="mt-5 w-full" name="item-org-id" v-validate="'required'" />

        <vs-input label="ข้อมูลหน่วยงาน 2" v-model="Org02" class="mt-5 w-full" name="item-org-2" v-validate="'required'" />

        <vs-input label="ข้อมูลหน่วยงาน 3" v-model="Org03" class="mt-5 w-full" name="item-org-3" v-validate="'required'" />

        <vs-input label="ข้อมูลหน่วยงาน 5" v-model="Org05" class="mt-5 w-full" name="item-org-5" v-validate="'required'" />


      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">ตกลง</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">ยกเลิก</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      dataId: null,
      org_id: "",
      Org02: "",
      Org03: "",
      Org05: "",
      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  watch: {
    isSidebarActive(val) {
      console.log('val', val)
      if(!val) return;
      if(Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset()
      }else {
        console.log('data', this.data)
        let { org_id, Org01, Org02, Org03, Org04, Org05, _id } = JSON.parse(JSON.stringify(this.data));
        this.dataId = _id;
        this.org_id = org_id;
        this.Org02 = Org02;
        this.Org03 = Org03;
        this.Org05 = Org05;
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.org_id && this.Org01 && this.Org02 && this.Org03
    },
    scrollbarTag() { return this.$store.getters.scrollbarTag }
  },
  methods: {
    initValues() {
      if(this.data._id) return;
        this.dataId = null;
        this.org_id = ""
        this.Org02 = ""
        this.Org03 = ""
        this.Org05 = ""
    },
    submitData() {
      this.$validator.validateAll().then(result => {
          if (result) {
            const obj = {
              id: this.dataId,
              org_id: this.org_id,
              Org02: this.Org02,
              Org03: this.Org03,
              Org05: this.Org05
            };
            if(obj.id !== null) {
              this.$store.dispatch("config/updateItem", obj)
                .then(() => {
                  this.$store.dispatch("config/fetchDataListItems")
                })
            }else{
              delete obj.id;
              this.$store.dispatch("config/addItem", obj).then(() => {
                this.$store.dispatch("config/fetchDataListItems")
              })
            }

            this.$emit('closeSidebar');
            this.initValues()
          }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
