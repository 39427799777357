import axios from "@/axios.js"

export default {
  fetchDataItem({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/config")
        .then((response) => {
          commit('SET_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/config/list")
        .then((response) => {
          commit('SET_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/config/${item._id}`, item)
        .then((response) => {
          // commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/config`, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
