<template>
  <div id="data-list-list-view" class="data-list-container">

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" v-model="selected" search :data="datas">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

      </div>

      <template slot="thead">
        <vs-th>ลำดับที่</vs-th>
        <vs-th sort-key="org_id">รหัสหน่วยงาน</vs-th>
        <vs-th sort-key="Org02">ข้อมูลหน่วยงาน 2</vs-th>
        <vs-th sort-key="Org03">ข้อมูลหน่วยงาน 3</vs-th>
        <vs-th sort-key="Org05">ข้อมูลหน่วยงาน 5</vs-th>
        <vs-th>คำสั่ง</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <p class="product-name font-medium">{{ indextr + 1 }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ tr.org_id }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ tr.Org02 }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium">{{ tr.Org03 }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium">{{ tr.Org05 }}</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
<!--                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" />-->
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>

<script>
import DataViewSidebar from './ConfigViewSidebar.vue'
import moduleConfig from "@/store/config/moduleConfig.js"

export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      selected: [],
      // products: [],
      itemsPerPage: 20,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    datas() {
      return this.$store.state.config.item
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    }
  },
  methods: {
    async getConfigs() {
      await this.$store.dispatch("config/fetchDataListItems")
      this.$vs.loading.close();
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    }
  },
  created() {
    this.$vs.loading()
    if(!moduleConfig.isRegistered) {
      this.$store.registerModule('config', moduleConfig)
      moduleConfig.isRegistered = true
    }
    this.getConfigs();
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
          justify-content: left;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: left;
    }
  }
}
</style>
